/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/es5-shim@4.6.7/es5-shim.min.js
 * - /npm/es6-shim@0.35.6/es6-shim.min.js
 * - /npm/jquery@3.6.1/dist/jquery.min.js
 * - /npm/jquery-ui@1.13.2/dist/jquery-ui.min.js
 * - /npm/inputmask@5.0.7/dist/jquery.inputmask.min.js
 * - /npm/chartist@1.2.1/dist/index.umd.min.js
 * - /npm/luxon@3.0.4/build/global/luxon.min.js
 * - /npm/toastr@2.1.4/build/toastr.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
